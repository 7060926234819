import { CheckCircle } from "react-feather"
import Layout from "../components/layout"
import PulseLoader from "react-spinners/PulseLoader"
import React from "react"
import SEO from "../components/seo"
import axios from "axios"

const Input = ({
  id,
  label,
  placeholder,
  type,
  value,
  name,
  onChange,
  errorMessage,
}) => (
  <div className="measure input-container">
    <div
      htmlFor={`${id}`}
      className={`f6 fw7 db mb2 label-text ${
        errorMessage ? "input-error-label" : ""
      }`}
    >
      {label}
    </div>
    <input
      id={`${id}`}
      className={`sign-up-input input-reset bb mb2 db w-100 pv3 ${
        errorMessage ? "input-error-border" : ""
      }`}
      type={type ? type : "text"}
      placeholder={errorMessage || placeholder}
      aria-describedby={`${id}-desc`}
      value={value}
      name={name}
      onChange={onChange}
    />
  </div>
)

const Checkbox = ({ onChange }) => {
  return (
    <div className="vanity-checkbox">
      <input
        type="checkbox"
        onChange={onChange}
        name="check"
        value="valuable"
        id="check"
      />
      <label htmlFor="check"></label>
    </div>
  )
}

const SignUpInfoSection = ({}) => (
  <div>
    <h1 className="main-container-title f2 lh-title title-pink">
      Have your beauty inventory always with you
    </h1>
    <p className="lh-copy">
      You don't need to use giant Excel sheets, pen and paper anymore. Vanity is
      your best friend when it comes to keeping track of all your beauty
      products.
    </p>
    <p className="lh-copy db-ns dn">With Vanity, you can;</p>
    <ul className="sign-up-feature-list db-ns dn">
      <li>Add items to your inventory in seconds</li>
      <li>Search all your items by name, brand or category</li>
      <li>Create new looks</li>
    </ul>
    <p className="lh-copy db-ns dn">
      Join our community and digitize your vanity within seconds!
    </p>
  </div>
)

const SignUpButton = ({ onClick, isLoading }) => (
  <button onClick={onClick} className="sign-up-button bn mb3">
    {isLoading ? (
      <PulseLoader color={"white"} size={10} />
    ) : (
      <span>Sign Up</span>
    )}
  </button>
)

const SignUpForm = ({ onSubmit, errorMessage }) => {
  const [name, setName] = React.useState(undefined)
  const [email, setEmail] = React.useState(undefined)
  const [password, setPassword] = React.useState(undefined)
  const [agreed, setAgreed] = React.useState(undefined)
  const [isLoading, setIsLoading] = React.useState(false)

  const nameErrorMessage = name === "" ? "Enter your full name" : ""
  const emailErrorMessage = email === "" ? "Enter a valid email" : ""
  const passwordErrorMessage = password === "" ? "Enter your password" : ""
  const agreedErrorMessage =
    agreed === false ? (
      <span className="input-error-label">
        You need to agree{" "}
        <a href="https://app.termly.io/document/privacy-policy/86d0e2be-ac07-41fd-91ee-f7418d838747">
          terms of service
        </a>
      </span>
    ) : (
      ""
    )

  const initializeSubmit = () => {
    let isValid = true
    if (!name) {
      setName("")
      isValid = false
    }
    if (!email) {
      setEmail("")
      isValid = false
    }
    if (!password) {
      setPassword("")
      isValid = false
    }
    if (!agreed) {
      setAgreed(false)
      isValid = false
    }

    if (!isValid) {
      return
    }

    setIsLoading(true)
    onSubmit({ name, email, password }).then(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className="sign-up-container pa4-ns ph0 pv3 br4">
      <Input
        id="fullname"
        name="fullname"
        label="Full Name"
        placeholder="Full Name"
        value={name || ""}
        errorMessage={nameErrorMessage}
        onChange={event => setName(event.target.value)}
      />
      <Input
        id="email"
        name="email"
        label="Email"
        placeholder="Email"
        value={email || ""}
        errorMessage={emailErrorMessage}
        onChange={event => setEmail(event.target.value)}
      />
      <Input
        id="password"
        name="password"
        label="Password"
        placeholder="Password"
        type="password"
        value={password || ""}
        errorMessage={passwordErrorMessage}
        onChange={event => setPassword(event.target.value)}
      />
      <div className="checkbox mv3 flex items-center">
        <Checkbox
          onChange={() => {
            console.log(`Setting agreed to:${!agreed}`)
            setAgreed(!agreed)
          }}
        />
        <span className="agreement">
          {agreedErrorMessage ? (
            agreedErrorMessage
          ) : (
            <span>
              I agree to the Vanity&nbsp;
              <a href="https://app.termly.io/document/privacy-policy/86d0e2be-ac07-41fd-91ee-f7418d838747">
                Terms of Service
              </a>
            </span>
          )}
        </span>
      </div>
      {errorMessage ? (
        <div className="error-message mt1">{errorMessage}</div>
      ) : null}
      <SignUpButton isLoading={isLoading} onClick={initializeSubmit} />
      <p className="small-info f6 lh-copy">
        Already a part of Vanity?{" "}
        <a href="https://apps.apple.com/tt/app/vanity-beauty-organization/id1476182117?ign-mpt=uo%3D2">
          Download the app
        </a>{" "}
        to get started
      </p>
    </div>
  )
}

const SignUpSuccess = ({ name }) => (
  <div className="center tc success w-80 flex items-center">
    <div>
      <CheckCircle color={"#52b788"} size={60} />
      <h1>Welcome to Vanity!</h1>
      <p className="f4 lh-copy">
        {name}, we couldn't be more excited to have you on Vanity. You can get
        started by{" "}
        <a href="https://apps.apple.com/tt/app/vanity-beauty-organization/id1476182117?ign-mpt=uo%3D2">
          downloading our app here
        </a>{" "}
        and signing in!
      </p>
    </div>
  </div>
)

const SignUp = () => {
  const [errorMessage, setErrorMessage] = React.useState()
  const [successObj, setSuccessObj] = React.useState({ success: false })

  const onSubmit = ({ name, email, password }) => {
    return axios
      .post("/.netlify/functions/register", { fullname: name, email, password })
      .then(response => {
        if (response["data"]) {
          return setErrorMessage(response["data"])
        }
        setSuccessObj({ success: true, name })
      })
  }

  const { success, name } = successObj
  return (
    <Layout>
      <SEO title="Sign Up" />
      <div className="container flex ph4 pv2-ns pv0 ph0-ns sign-up-page">
        {success ? (
          <SignUpSuccess name={name} />
        ) : (
          <div className={"flex flex-row-ns flex-column"}>
            <div className="w-50-ns w-100 pt4-ns pt0">
              <SignUpInfoSection />
            </div>
            <div className="w-50-ns w-100 flex justify-center">
              <SignUpForm onSubmit={onSubmit} errorMessage={errorMessage} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default SignUp
